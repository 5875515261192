import React from 'react'
import { BoundedContent, Breadcrumbs as SwanBreadcrumbs, Breadcrumb as SwanBreadcrumb } from '@vp/swan'
import { Breadcrumb, BreadcrumbsProps } from './types'
import { useSwanStyleKeys } from '@vp/ubik-context'

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  useSwanStyleKeys(['core'])

  if (props.breadcrumbs === undefined || props.breadcrumbs.length === 0) {
    return <div />
  }
  return (
    <BoundedContent>
      <SwanBreadcrumbs aria-label='breadcrumbs'>
        {props.breadcrumbs.map((breadcrumb) => <BreadcrumbItem key={breadcrumb.position} breadcrumb={breadcrumb} itemsCount={props.breadcrumbs.length} />)}
      </SwanBreadcrumbs>
    </BoundedContent>
  )
}

type BreadcrumbProps = {
  key: number
  itemsCount: number
  breadcrumb: Breadcrumb
}
function BreadcrumbItem (props: BreadcrumbProps) {
  const { breadcrumb, itemsCount } = props
  const isLastBreadcrumb = breadcrumb.position === itemsCount

  return (
    <SwanBreadcrumb key={breadcrumb.position}>
      {!breadcrumb.available
        ? (
            breadcrumb.name
          )
        : (
          <a href={breadcrumb.url} {...(isLastBreadcrumb && { 'aria-current': 'page' })}>
            {breadcrumb.name}
          </a>
          )}
    </SwanBreadcrumb>
  )
}
